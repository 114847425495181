import { ComponentType, lazy } from 'react';

export function lazyRetry<T extends ComponentType<any>>(
  componentImport: Parameters<typeof lazy<T>>[0],
  chunkIdentifier: string
): Promise<{ default: T }> {
  const sessionStorageKey = `retry-lazy-refreshed-${chunkIdentifier}`;

  return new Promise((resolve, reject) => {
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(sessionStorageKey) || 'false'
    );

    componentImport()
      .then((component) => {
        console.log('RESOLVED-----');
        console.log('hasRefreshed when RESOLVED', hasRefreshed);

        window.sessionStorage.setItem(sessionStorageKey, 'false');
        resolve(component);
      })
      .catch((error) => {
        console.log('ERRRRRRRORRRRR', error);
        console.log('hasRefreshed when caught', hasRefreshed);
        if (!hasRefreshed) {
          window.sessionStorage.setItem(sessionStorageKey, 'true');
          return window.location.reload();
        }
        reject(error);
      });
  });
}

export const NatashaWelcome = lazy(() =>
  lazyRetry(() => import('../pages/onboard/NatashaWelcome'), 'NatashaWelcome')
);
export const ProjectSelection = lazy(() =>
  lazyRetry(
    () => import('../pages/onboard/ProjectSelection'),
    'ProjectSelection'
  )
);
export const NatashaRecommendedPlan = lazy(() =>
  lazyRetry(
    () => import('../pages/onboard/NatashaRecommendedPlan'),
    'NatashaRecommendedPlan'
  )
);
export const ChoosePlan = lazy(() =>
  lazyRetry(() => import('../pages/onboard/plans/ChoosePlan'), 'ChoosePlan')
);
export const OwnerOverview = lazy(() =>
  lazyRetry(() => import('../pages/onboard/OwnerOverview'), 'OwnerOverview')
);
export const ItAdminOverview = lazy(() =>
  lazyRetry(() => import('../pages/onboard/ItAdminOverview'), 'ItAdminOverview')
);
export const WaitingOnPayment = lazy(() =>
  lazyRetry(
    () => import('../pages/onboard/WaitingOnPayment'),
    'WaitingOnPayment'
  )
);
export const MemberDashboard = lazy(() =>
  lazyRetry(() => import('../pages/onboard/MemberDashboard'), 'MemberDashboard')
);

export const CheckoutPlanSelection = lazy(() =>
  lazyRetry(
    () => import('../pages/plan-checkout/CheckoutPlanSelection'),
    'CheckoutPlanSelection'
  )
);
export const PlanBillingDetails = lazy(() =>
  lazyRetry(
    () => import('../pages/plan-checkout/PlanBillingDetails'),
    'PlanBillingDetails'
  )
);
export const PlanPayment = lazy(() =>
  lazyRetry(() => import('../pages/plan-checkout/PlanPayment'), 'PlanPayment')
);

export const PaymentSuccess = lazy(() =>
  lazyRetry(
    () => import('../pages/payment-success/PaymentSuccess'),
    'PaymentSuccess'
  )
);

export const MemberOnboarding = lazy(() =>
  lazyRetry(
    () => import('../pages/member-onboarding/MemberOnboarding'),
    'MemberOnboarding'
  )
);
export const NatashaIntro = lazy(() =>
  lazyRetry(
    () => import('../pages/member-onboarding/NatashaIntro'),
    'NatashaIntro'
  )
);
export const ProfileSetup = lazy(() =>
  lazyRetry(
    () => import('../pages/member-onboarding/ProfileSetup'),
    'ProfileSetup'
  )
);
export const ProfilePhoto = lazy(() =>
  lazyRetry(
    () => import('../pages/member-onboarding/ProfilePhoto'),
    'ProfilePhoto'
  )
);

export const WorkspaceCustomisation = lazy(() =>
  lazyRetry(
    () => import('../pages/workspace-customisation/WorkspaceCustomisation'),
    'WorkspaceCustomisation'
  )
);
export const CompanySetup = lazy(() =>
  lazyRetry(
    () => import('../pages/workspace-customisation/CompanySetup'),
    'CompanySetup'
  )
);
export const AccountName = lazy(() =>
  lazyRetry(
    () => import('../pages/workspace-customisation/AccountName'),
    'AccountName'
  )
);
export const AccountLogo = lazy(() =>
  lazyRetry(
    () => import('../pages/workspace-customisation/AccountLogo'),
    'AccountLogo'
  )
);

export const CreditsAndPlan = lazy(() =>
  lazyRetry(
    () => import('../pages/plan-payments/CreditsAndPlan'),
    'CreditsAndPlan'
  )
);
export const PlanPaymentLogs = lazy(() =>
  lazyRetry(
    () => import('../pages/plan-payments/PlanPaymentLogs'),
    'PlanPaymentLogs'
  )
);
export const PlanPaymentsBillingDetails = lazy(() =>
  lazyRetry(
    () => import('../pages/plan-payments/PlanPaymentsBillingDetails'),
    'PlanPaymentsBillingDetails'
  )
);

export const CompanyIntegrations = lazy(() =>
  lazyRetry(
    () => import('../pages/company/CompanyIntegrations'),
    'CompanyIntegrations'
  )
);
export const CompanyIntegrationsDetail = lazy(() =>
  lazyRetry(
    () => import('../pages/company/CompanyIntegrationsDetail'),
    'CompanyIntegrationsDetail'
  )
);

export const MemberProfile = lazy(() =>
  lazyRetry(
    () => import('../pages/member-profile/MemberProfileNavigation'),
    'MemberProfileNavigation'
  )
);

export const PlaygroundBuildcard = lazy(() =>
  lazyRetry(
    () => import('../pages/building/projects/PlaygroundBuildcard'),
    'PlaygroundBuildcard'
  )
);
export const RefineStudio = lazy(() =>
  lazyRetry(
    () => import('../pages/building/projects/RefineStudio'),
    'RefineStudio'
  )
);
export const MeetingSummary = lazy(() =>
  lazyRetry(
    () => import('../pages/building/projects/MeetingSummary'),
    'MeetingSummary'
  )
);
export const KpiDetail = lazy(() =>
  lazyRetry(() => import('../pages/company/goals/KpiDetail'), 'KpiDetail')
);

export const BuildingSpaces = lazy(() =>
  lazyRetry(() => import('../pages/building/BuildingSpaces'), 'BuildingSpaces')
);

// export const BuildingSpaces = lazy(() =>
//   lazyRetry(
//     () => Math.random() > 0.5 ?
//       import('../pages/building/BuildingSpaces') :
//       new Promise((resolve, reject) => {
//         reject(new Error('ChunkLoadError'))
//       }),
//     'BuildingSpaces'
//   )
// );

export const SpaceOverview = lazy(() =>
  lazyRetry(() => import('../pages/building/SpaceOverview'), 'SpaceOverview')
);
export const SpaceTeam = lazy(() =>
  lazyRetry(() => import('../pages/building/SpaceTeam'), 'SpaceTeam')
);
export const SpaceCredits = lazy(() =>
  lazyRetry(() => import('../pages/building/SpaceCredits'), 'SpaceCredits')
);
export const CompanySettings = lazy(() =>
  lazyRetry(() => import('../pages/company/CompanySettings'), 'CompanySettings')
);
export const CompanyDefaultRolesPermissions = lazy(() =>
  lazyRetry(
    () => import('../pages/company/CompanyDefaultRolesPermissions'),
    'CompanyDefaultRolesPermissions'
  )
);
export const CompanyCustomRolesPermissions = lazy(() =>
  lazyRetry(
    () => import('../pages/company/CompanyCustomRolesPermissions'),
    'CompanyCustomRolesPermissions'
  )
);
export const MembersDashboard = lazy(() =>
  lazyRetry(
    () => import('../pages/members/MembersDashboard'),
    'MembersDashboard'
  )
);
export const MemberDetail = lazy(() =>
  lazyRetry(() => import('../pages/members/MemberDetail'), 'MemberDetail')
);

export const SpaceTopNavigation = lazy(() =>
  lazyRetry(() => import('../pages/building/HeaderTop'), 'HeaderTop')
);

export const DuplicateBuildcard = lazy(() =>
  lazyRetry(
    () => import('../pages/company/duplicate-buildcards/DuplicateBuildcard'),
    'DuplicateBuildcard'
  )
);

export const BusinessGoals = lazy(() =>
  lazyRetry(
    () => import('../pages/company/goals/BusinessGoals'),
    'BusinessGoals'
  )
);
export const BusinessGoalDetails = lazy(() =>
  lazyRetry(
    () => import('../pages/company/goals/BusinessGoalDetails'),
    'BusinessGoalDetails'
  )
);
export const CompanyCredits = lazy(() =>
  lazyRetry(
    () => import('../pages/company/credits/CompanyCredits'),
    'CompanyCredits'
  )
);
export const Checkout = lazy(() =>
  lazyRetry(() => import('../pages/onboard/plans/Checkout'), 'Checkout')
);
export const ForgotPassword = lazy(() =>
  lazyRetry(() => import('../pages/pre-login/ForgotPassword'), 'ForgotPassword')
);
export const SpaceGoalOverview = lazy(() =>
  lazyRetry(
    () => import('../pages/building/goals/SpaceGoalOverview'),
    'SpaceGoalOverview'
  )
);
export const SpaceIdeaboard = lazy(() =>
  lazyRetry(() => import('../pages/building/SpaceIdeaboard'), 'SpaceIdeaboard')
);
export const PlaygroundMeeting = lazy(() =>
  lazyRetry(
    () => import('../pages/building/projects/PlaygroundMeeting'),
    'PlaygroundMeeting'
  )
);
export const PlaygroundWelcome = lazy(() =>
  lazyRetry(
    () => import('../pages/building/projects/PlaygroundWelcome'),
    'PlaygroundWelcome'
  )
);
export const PlaygroundWhiteboard = lazy(() =>
  lazyRetry(
    () => import('../pages/building/projects/PlaygroundWhiteboard'),
    'PlaygroundWhiteboard'
  )
);
export const ResetPassword = lazy(() =>
  lazyRetry(() => import('../pages/pre-login/ResetPassword'), 'ResetPassword')
);
export const Catalog = lazy(() =>
  lazyRetry(() => import('../pages/company/catalog/Catalog'), 'Catalog')
);
export const Signup = lazy(() =>
  lazyRetry(() => import('../pages/pre-login/signup'), 'signup')
);
