import { createWriteStream } from 'streamsaver';
import instance from '../interceptor';
import { currentSpace } from '../store/slices/companySlice';
import { currentGoalDetail } from '../store/slices/goalSlice';
import { setSpaceCreditData } from '../store/slices/spaceCreditSlice';
import { setSuccessMsg } from '../store/slices/successSlice';
import { addUserData, setCompany } from '../store/slices/userSlice';
import {
  CATALOG_INDEX,
  CATALOGS,
  CHANGE_REQUESTS,
  COMPANY,
  ENT_SIGNED_URL,
  ENT_USERS,
  MAX_CATALOG_DESCRIPTION_LENGTH,
  MAX_NAME_LENGTH,
  PROJECTS,
  SESSION_DETAIL,
  SPACES,
  USER_SEARCH,
} from '../util/constant';
import {
  getCookie,
  globalError,
  makeDoubleDigit,
  setCompanyToStorage,
  setCookie,
  setLoader,
  setUserToStorage,
} from '../util/util';

export const getCurrentSpaceDetail = async (
  dispatch,
  compId,
  paramId,
  navigate = null,
  route = null,
  transition = null
) => {
  try {
    const url = `${SPACES.replace(':id', compId)}/${paramId}`;
    const spaceRes = await instance.get(url);
    dispatch(currentSpace(spaceRes['space']));
    if (navigate) {
      transition(() => {
        navigate(route);
      });
    }
  } catch (e) {
    globalError(dispatch, e);
  }
};

export const getAllTags = async (compSel, dispatch, query?) => {
  try {
    let url = `${COMPANY}/${compSel?.id}/tags`;
    if (query) {
      url += `?q=${encodeURIComponent(query?.toLowerCase())}`;
    }
    return await instance.get(url);
  } catch (e) {
    globalError(dispatch, e);
  }
};

export const addTag = async (compSel, dispatch, tagName, card) => {
  try {
    tagName = tagName?.trim()?.toLowerCase();
    if (!tagName) return;
    let url = `${COMPANY}/${compSel?.id}/entity_tags`;
    let payload = {
      entity_tag: {
        name: encodeURIComponent(tagName),
        taggable_id: card?.enterprise_id ?? card?.system_id ?? card?.id,
        taggable_type: 'BuildCard',
      },
    };
    let res = await instance.post(url, payload);
    dispatch(setSuccessMsg('Tag added successfully.'));
    return res;
  } catch (e) {
    globalError(dispatch, e);
  }
};

export const handleDelete = async (dispatch, compSel, tagId) => {
  try {
    const url = `${COMPANY}/${compSel?.id}/entity_tags/${tagId}`;
    let res = await instance.delete(url);
    dispatch(setSuccessMsg('Tag deleted successfully.'));
    return res;
  } catch (err) {
    globalError(dispatch, err);
  }
};

export const getSpaceMemberList = async (
  companySel,
  currentSpace,
  params,
  signal,
  dispatch
) => {
  try {
    if (companySel?.id && (params?.id || currentSpace?.id)) {
      let url = `${SPACES.replace(':id', companySel?.id)}/${params?.id ?? currentSpace?.id}/all_members`;
      return await instance.get(url, { signal });
    }
  } catch (e) {
    globalError(dispatch, e);
  }
};

export const getConfirmedUserList = async (
  companySel,
  searchText,
  dispatch,
  setLoading
) => {
  try {
    setLoading(true);
    let url = `${ENT_USERS.replace(':id', companySel?.id)}?confirmed=true`;
    if (searchText) {
      url += `&q=${searchText}`;
    }
    return await instance.get(url);
  } catch (e) {
    globalError(dispatch, e);
  } finally {
    setLoading(false);
  }
};

export const getUserListWithoutPermission = async (
  companySel,
  searchText,
  dispatch,
  setLoading,
  confirmed = false
) => {
  try {
    setLoading(true);
    let url = `${USER_SEARCH.replace(':id', companySel?.id)}`;
    if (confirmed) {
      url += `?confirmed=true`;
    }
    if (searchText) {
      url += `${confirmed ? '&' : '?'}q=${searchText}`;
    }
    return await instance.get(url);
  } catch (e) {
    globalError(dispatch, e);
  } finally {
    setLoading(false);
  }
};

export const getAllSpaceGoals = async (dispatch, compSel, params) => {
  try {
    const url = `${SPACES.replace(':id', compSel?.id)}/${params?.id}/space_goals`;
    let res = await instance.get(url);
    return res;
  } catch (e) {
    globalError(dispatch, e);
  }
};

const getSpaceCreditDetail = async (compSel, currentSpaceSel) => {
  const url = `${COMPANY}/${compSel?.id}/plan_credit_subscriptions/${compSel?.active_plan_credit_subscription?.id}/space_credits/${currentSpaceSel?.active_space_credit_id}`;
  return instance.get(url);
};

const getBuildcardConsumption = async (compSel, currentSpaceSel) => {
  const url = `${COMPANY}/${compSel?.id}/plan_credit_subscriptions/${compSel?.active_plan_credit_subscription?.id}/space_credits/${currentSpaceSel?.active_space_credit_id}/buildcard_consumption_data`;
  return instance.get(url);
};

const getDraftBuildcardEstimation = async (compSel, currentSpaceSel) => {
  const url = `${COMPANY}/${compSel?.id}/plan_credit_subscriptions/${compSel?.active_plan_credit_subscription?.id}/space_credits/${currentSpaceSel?.active_space_credit_id}/draft_build_cards_estimation`;
  return instance.get(url);
};

export const getSpaceCreditData = async (
  compSel,
  currentSpaceSel,
  dispatch
) => {
  try {
    let res = await Promise.all([
      getSpaceCreditDetail(compSel, currentSpaceSel),
      getBuildcardConsumption(compSel, currentSpaceSel),
      getDraftBuildcardEstimation(compSel, currentSpaceSel),
    ]);
    dispatch(setSpaceCreditData(res));
    return res;
  } catch (err) {
    globalError(dispatch, err);
  }
};

export const getGoalDetailCom = async (params, compSel, dispatch) => {
  try {
    const goalId = params?.goal_id;
    const url = `${COMPANY}/${compSel?.id}/company_goals/${goalId}`;
    let res = await instance.get(url);
    dispatch(currentGoalDetail(res?.['company_goal']));
    return res?.['company_goal'];
  } catch (e) {
    globalError(dispatch, e);
  }
};

export const updateChangeRequestState = async (
  selectedBC,
  dispatch,
  compSel,
  params,
  state,
  note = null
) => {
  if (selectedBC?.change_request_id) {
    try {
      setLoader(dispatch, true);
      const url = `${CHANGE_REQUESTS.replace(':id', compSel?.id).replace(':space_id', params?.id).replace(':build_card_id', params?.bc_id)}/${selectedBC?.change_request_id}`;
      const payload = {
        change_request: {
          state,
          note,
          event: state !== 'update' ? state : 'modify',
        },
      };
      return await instance.patch(url, payload);
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setLoader(dispatch, false);
    }
  }
};

export const clearBuildCardCache = async (
  buildCardId,
  spaceId,
  companyId,
  dispatch
) => {
  try {
    const url = `${PROJECTS.replace(':id', companyId).replace(':space_id', spaceId)}/build_cards/${buildCardId}/clear_cache`;
    instance.patch(url);
  } catch (e) {
    globalError(dispatch, e);
  }
};

export const createExternalAppSession = async (
  params,
  compSel,
  app_name,
  dispatch
) => {
  try {
    const url = `${PROJECTS.replace(':id', compSel?.id).replace(':space_id', params?.id)}/external_app_sessions`;
    const res = await instance.post(url, { session: { app_name } });
  } catch (e) {
    globalError(dispatch, e);
  }
};

export const sendSlackNotification = async (
  app,
  time,
  screen,
  url,
  dispatch
) => {
  try {
    let payload = {
      notification_type: 'iframe_loading',
      payload: {
        meta_data: {
          app_name: app,
          time_taken: `${makeDoubleDigit(time / 1000)} sec`,
          screen: screen,
        },
        url: `${process.env.REACT_APP_DOMAIN}/company/spaces/${url}`,
      },
    };
    await instance.post(`external/notifications`, payload);
  } catch (err) {
    globalError(dispatch, err);
  }
};

export const renameBuildCard = async (
  companyId,
  spaceId,
  buildCardId,
  name,
  updateBuildCardAttributes,
  dispatch
) => {
  try {
    const url = `${PROJECTS.replace(':id', companyId).replace(':space_id', spaceId)}/build_cards/${buildCardId}/update_name`;
    await instance.patch(url, { build_card: { name } });
    updateBuildCardAttributes();
    dispatch(setSuccessMsg('Buildcard name updated'));
  } catch (e) {
    globalError(dispatch, e);
  }
};

export const fetchCompanyCatalogs = async (companyId, dispatch) => {
  try {
    const url = `${CATALOGS.replace(':id', companyId)}`;
    const response = await instance.get(url);
    return response;
  } catch (e) {
    globalError(dispatch, e);
  }
};

export const fetchCatalogDetails = async (companyId, catalogId, dispatch) => {
  try {
    const url = `${CATALOGS.replace(':id', companyId)}/${catalogId}`;
    const response = await instance.get(url);
    return response;
  } catch (e) {
    globalError(dispatch, e);
  }
};

export const createCatalog = async (
  companyId: string,
  formData: FormData,
  dispatch
) => {
  try {
    const url = CATALOGS.replace(':id', companyId);
    const response = await instance.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    dispatch(
      setSuccessMsg({
        message: 'Your template has been created',
        label: 'Browse Templates',
        refLink: CATALOG_INDEX,
      })
    );
    return response;
  } catch (e) {
    globalError(dispatch, e);
  }
};

export const updateCatalog = async (
  companyId: string,
  catalogId: string,
  formData: FormData,
  dispatch
) => {
  try {
    const url = `${CATALOGS.replace(':id', companyId)}/${catalogId}`;
    const response = await instance.patch(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    dispatch(setSuccessMsg('Template updated successfully'));
    return response;
  } catch (e) {
    globalError(dispatch, e);
  }
};

export const destroyCatalog = async (
  companyId: string,
  catalogId: string,
  dispatch
) => {
  try {
    const url = `${CATALOGS.replace(':id', companyId)}/${catalogId}`;
    const response = await instance.delete(url);
    dispatch(setSuccessMsg('Catalogue destroyed successfully'));
    return response;
  } catch (e) {
    globalError(dispatch, e);
  }
};

export const modifyBuildCardAttributes = async (
  companyId,
  spaceId,
  buildCardId,
  updatedBuildCard,
  updateBuildCardAttributes,
  dispatch,
  message
) => {
  try {
    const url = `${PROJECTS.replace(':id', companyId).replace(':space_id', spaceId)}/build_cards/${buildCardId}`;
    await instance.put(url, { build_card: updatedBuildCard });
    updateBuildCardAttributes();
    dispatch(setSuccessMsg(message));
  } catch (e) {
    globalError(dispatch, e);
  }
};

export const validateCatalogForm = (catalogParams, setErrors) => {
  const newErrors = {
    name: '',
    description: '',
    thumbnail: '',
  };

  if (!catalogParams.title?.trim()) {
    newErrors.name = 'Add a template name';
  }

  if (catalogParams.title?.trim()?.length > MAX_NAME_LENGTH) {
    newErrors.name = `Template name cannot exceed ${MAX_NAME_LENGTH} characters`;
  }

  if (!catalogParams.description?.trim()) {
    newErrors.description = 'Add a template description';
  } else if (
    catalogParams.description.length > MAX_CATALOG_DESCRIPTION_LENGTH
  ) {
    newErrors.description = `Description cannot exceed ${MAX_CATALOG_DESCRIPTION_LENGTH} characters`;
  }

  if (catalogParams.app_builder_icon_file) {
    const validTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    if (!validTypes.includes(catalogParams.app_builder_icon_file.type)) {
      newErrors.thumbnail =
        'Incorrect format. Try using a jpeg, jpg, or png format.';
    } else if (catalogParams.app_builder_icon_file.size > 1024 * 1024) {
      // 1MB limit
      newErrors.thumbnail =
        'File size is too big. Please resize the image and try again.';
    }
  }

  setErrors(newErrors);
  return !Object.values(newErrors).some((error) => error);
};

export const createDefaultIcon = (text: string) => {
  const canvas = document.createElement('canvas');
  canvas.width = 56;
  canvas.height = 56;
  const ctx = canvas.getContext('2d');
  const radius = 4; // Controls the roundness of corners

  // Set background with rounded corners
  ctx.fillStyle = '#7C4DFF';
  ctx.beginPath();
  ctx.moveTo(radius, 0);
  ctx.lineTo(canvas.width - radius, 0);
  ctx.quadraticCurveTo(canvas.width, 0, canvas.width, radius);
  ctx.lineTo(canvas.width, canvas.height - radius);
  ctx.quadraticCurveTo(
    canvas.width,
    canvas.height,
    canvas.width - radius,
    canvas.height
  );
  ctx.lineTo(radius, canvas.height);
  ctx.quadraticCurveTo(0, canvas.height, 0, canvas.height - radius);
  ctx.lineTo(0, radius);
  ctx.quadraticCurveTo(0, 0, radius, 0);
  ctx.closePath();
  ctx.fill();

  // Set text properties
  ctx.fillStyle = '#FFFFFF';
  ctx.font = '24px Rubik';
  ctx.textAlign = 'center';
  ctx.textBaseline = 'middle';

  // Get initials from text
  const initials =
    text
      ?.split(' ')
      ?.map((word) => word?.charAt(0))
      ?.join('')
      ?.toUpperCase()
      ?.slice(0, 2) || '';

  // Draw text
  ctx.fillText(initials, canvas.width / 2, canvas.height / 2);

  return canvas.toDataURL('image/png');
};

export const loadRubikFont = async () => {
  try {
    const font = new FontFace(
      'Rubik',
      'url(https://fonts.gstatic.com/s/rubik/v28/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-B4i1UA.ttf)'
    );
    await font.load();
    document.fonts.add(font);
    return true;
  } catch (error) {
    console.error('Error loading Rubik font:', error);
    return false;
  }
};

export const downloadFile = (url, fileName, dispatch) => {
  return fetch(url).then((res) => {
    const fileStream = createWriteStream(fileName);
    const writer = fileStream.getWriter();
    if (res.body.pipeTo) {
      writer.releaseLock();
      return res.body.pipeTo(fileStream);
    }

    const reader = res.body.getReader();
    const pump = () =>
      reader
        .read()
        .then(({ value, done }) =>
          done ? writer.close() : writer.write(value).then(pump)
        );

    return pump();
  });
};

export const downloadMeeting = async (url, title, dispatch) => {
  try {
    const recordingUrl = `${ENT_SIGNED_URL}`;
    let payload = {
      blob_url: url,
      provider: 'bmeet',
      attachment_type: 'video',
    };

    let resInt = (await instance.post(recordingUrl, payload)) as any;
    await downloadFile(resInt?.url, `${title}.mp4`, dispatch);
    dispatch(setSuccessMsg('File downloaded successfully.'));
  } catch (err) {
    globalError(dispatch, err);
  }
};

export const isTokenPresent = async (dispatch, detail = false) => {
  try {
    const userCookie = getCookie(process.env.REACT_APP_USER);
    const studioId = userCookie ? JSON.parse(userCookie)?.['id'] : null;
    const res = await instance.get(SESSION_DETAIL, {
      headers: { 'studio-id': studioId },
    });
    const companyRes = await instance.get(
      `${COMPANY}/${res['user']?.company?.id}`
    );
    setCookie(
      process.env.REACT_APP_USER_PLAN,
      JSON.stringify({
        care_type: companyRes['company']?.['plan']?.care_type,
        disabled_care_type: companyRes['company']?.['plan']?.disabled_care_type,
        hidden_care_type: companyRes['company']?.['plan']?.hidden_care_type,
        name: companyRes['company']?.name,
        speed_id: companyRes['company']?.['plan']?.speed_id,
      })
    );
    setUserToStorage(dispatch, addUserData, res, detail ? true : false);
    setCompanyToStorage(dispatch, setCompany, companyRes);
  } catch (e) {
    globalError(dispatch, e);
  } finally {
    setLoader(dispatch, false);
  }
};

export const createDiscussionForFeedback = async (
  value,
  compId,
  spaceId,
  bcId,
  dispatch,
  type = 'build_card'
) => {
  try {
    const url = `${PROJECTS.replace(':id', compId).replace(':space_id', spaceId)}/build_cards/${bcId}/build_card_discussions`;
    const payload = {
      build_card_discussion: {
        body: value,
        discussion_type: type,
      },
    };
    await instance.post(url, payload);
  } catch (e) {
    globalError(dispatch, e);
  }
};
